import React, { Component } from 'react';
import logo from './assets/imgs/logo.png';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import logoAxioma from './assets/imgs/logoAxioma.png';
import googlePlay from './assets/imgs/googlePlay.png';
import appleStore from './assets/imgs/appleStore.png';
import screenShot1 from './assets/imgs/screenShot1.jpg';
import screenShot2 from './assets/imgs/screenShot2.jpg';

const styles = {
  app: {
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    width: '100%',
    height: '100%',
    backgroundColor: '#ffffff',
  },
  header: {
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: '#18AD75',
    padding: '10px 50px',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  logo: {
    height: 80,
    borderRadius: 20,
  },
  menuButtons: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 4,
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuButton: {
    color: '#ffffff',
    fontSize: 20,
    fontWeight: '300',
    margin: 20,
    textAlign: 'center',
  },
  menuButtonLink: {
    textDecoration: 'none',
    color: '#ffffff',
  },
  body: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
    padding: 30,
    overflowY: 'auto',
  },
  quickInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  quickInfoTitle: {
    color: '#333333',
    fontSize: 50,
    fontWeight: '500',
    marginTop: 50,
    marginBottom: 100,
    textAlign: 'center',
  },
  quickInfoText: {
    color: '#333333',
    fontSize: 20,
    fontWeight: '500',
    margin: '50px 0',
    textAlign: 'center',
  },
  downloadImages: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 40,
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  logoDownload: {
    margin: 10,
    height: 50,
  },
  description: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    marginTop: 50,
    flexWrap: 'wrap',
  },
  descriptionLeft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: 20,
    flex: 1,
  },
  descriptionTitle: {
    color: '#333333',
    fontSize: 30,
    fontWeight: '500',
    margin: '5px 5px',
    textAlign: 'left',
  },
  descriptionText: {
    color: '#333333',
    fontSize: 18,
    fontWeight: '500',
    margin: '5px 5px',
    textAlign: 'left',
  },
  descriptionRight: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: 20,
    flex: 1,
    flexWrap: 'wrap',
  },
  screenshots: {
    margin: 5,
    height: 400,
  },
  functionalities: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: 50,
    width: '100%',
    flex: 1,
  },
  functionalitiesTitle: {
    color: '#333333',
    fontSize: 30,
    fontWeight: '500',
    margin: '5px 5px',
    textAlign: 'left',
    padding: 20,
  },
  functionalitiesStepper: {
    backgroundColor: '#ffffff',
  },
  functionalitiesStep: {
    "& $completed": {
      color: "lightgreen"
    },
    "& $active": {
      color: "pink"
    },
    "& $disabled": {
      color: "red"
    }
  },
  functionalitiesStepText: {
    color: '#333333',
  },
  functionalitiesPaper: {
    backgroundColor: '#ffffff',
  },
  stepperButton: {
    backgroundColor: '#18AD75',
    color: '#ffffff',
    margin: 5,
  },
  privacy: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: 50,
    width: '100%',
    flex: 1,
  },
  privacyTitle: {
    color: '#333333',
    fontSize: 30,
    fontWeight: '500',
    margin: '5px 5px',
    textAlign: 'left',
    padding: 20,
  },
  privacyDescription: {
    color: '#333333',
    fontSize: 15,
    fontWeight: '400',
    margin: '0px 5px',
    textAlign: 'left',
    padding: '0 20px',
  },
  footer: {
    display: 'flex',
    backgroundColor: '#18AD75',
    padding: '0 10px',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  axiomaLink: {
    margin: 5,
    textDecoration: 'none',
  },
  logoAxioma: {
    height: 15,
  },
}

function getSteps() {
  return ['Instala la aplicación', 'Suscribite', 'Crea tus campos, campañas y labores', 'Crea reportes'];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return 'Descarga la aplicación de App Store o Play Store y procede con la instalación. No te preocupes, la aplicación es completamente gratuita.';
    case 1:
      return 'Create una cuenta nueva utilizando tu mail de preferencia o login con Google.';
    case 2:
      return 'Ve al menú de creación para comenzar a agregar datos, y administra todas tus campañas acorde a tus necesidades.';
    case 3:
      return 'Crea reportes sobre cultivos o labores realizadas en los lotes de tus campos, y comparte el PDF con tus contactos.';
    default:
      return 'Paso desconocido.';
  }
}

class App extends Component {
  state = {
    activeStep: 0,
  };

  handleNext = () => {
    this.setState(state => ({
      activeStep: state.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  render() {
    const steps = getSteps();
    const { activeStep } = this.state;

    return (
      <div style={styles.app}>
        <div style={styles.header}>
          <a href="./#quickInfo">
            <img src={logo} style={styles.logo} alt="logo" />
          </a>
          <div style={styles.menuButtons}>
            <span style={styles.menuButton}><a href="./#description" style={styles.menuButtonLink}>DESCRIPCIÓN</a></span>
            <span style={styles.menuButton}><a href="./#functionalities" style={styles.menuButtonLink}>FUNCIONAMIENTO</a></span>
            <span style={styles.menuButton}><a href="./#privacy" style={styles.menuButtonLink}>POLÍTICA DE PRIVACIDAD</a></span>
          </div>
        </div>
        <div style={styles.body}>
          <div id="quickInfo" style={styles.quickInfo}>
            <span style={styles.quickInfoTitle}>
              Tu Cuaderno de Campo Digital
            </span>
            <span style={styles.quickInfoText}>
              Maneja tus campañas de <b>forma simple y rápida</b>.
            </span>
            <div style={styles.downloadImages}>
              <img src={googlePlay} style={styles.logoDownload} alt="google play" />
              <img src={appleStore} style={styles.logoDownload} alt="apple store" />
            </div>
          </div>
          <div id="description" style={styles.description}>
            <div style={styles.descriptionLeft}>
              <span style={styles.descriptionTitle}>
                Descripción
              </span>
              <span style={styles.descriptionText}>
                <b>AgroManager</b> es TOTALMENTE GRATUITA, y te permite generar la información de las labores realizadas en tus campos, de manera fácil e intuitiva.
                <br />Graba todas las operaciones de cultivo, el uso de insumos (semilla, abono, fitosanitarios), riegos, registro de precipitaciones y cosecha; a nivel de lotes y campos.
                <br />Con tu celular o tablet, podrás acceder en cualquier momento y lugar a los reportes generados, compartirlos, facilitar la gestión y ahorrar tiempo.
                <br /> Tendrás el historial de tu campo siempre en la palma de tu mano; también sin conexión.
              </span>
            </div>
            <div style={styles.descriptionRight}>
              <img src={screenShot1} style={styles.screenshots} alt="app screenshot" />
              <img src={screenShot2} style={styles.screenshots} alt="app screenshot" />
            </div>
          </div>
          <div id="functionalities" style={styles.functionalities}>
            <span style={styles.functionalitiesTitle}>
              Funcionamiento
            </span>
            <Stepper activeStep={activeStep} orientation="vertical" style={styles.functionalitiesStepper}>
              {steps.map((label, index) => (
                <Step key={label} classes={{ root: styles.functionalitiesStep, completed: {}, active: {} }}>
                  <StepLabel style={styles.functionalitiesStepText}><b>{label}</b></StepLabel>
                  <StepContent>
                    <Typography style={styles.functionalitiesStepText}>{getStepContent(index)}</Typography>
                    <div>
                      <div>
                        <Button
                          disabled={activeStep === 0}
                          onClick={this.handleBack}
                        >
                          Atrás
                        </Button>
                        <Button
                          variant="contained"
                          style={styles.stepperButton}
                          onClick={this.handleNext}
                        >
                          {activeStep === steps.length - 1 ? 'Finalizado' : 'Siguiente'}
                        </Button>
                      </div>
                    </div>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
            {activeStep === steps.length && (
              <Paper square elevation={0} style={styles.functionalitiesPaper}>
                <Typography style={styles.functionalitiesStepText}>¡Listo, ya puedes comenzar a utilizar AgroManager!</Typography>
                <Button onClick={this.handleReset} style={styles.stepperButton}>
                  Reiniciar
                </Button>
              </Paper>
            )}
          </div>
          <div id="privacy" style={styles.privacy}>
            <span style={styles.privacyTitle}>
              Política de Privacidad
            </span>
            <span style={styles.privacyDescription}>
              <b>INFORMACIÓN PARA USUARIO:</b> Lea atentamente el siguiente acuerdo de Licencia de Uso y privacidad. La instalación o uso de la App <b>AgroManager</b> supone que Usted acepta los términos del mismo.<br /><br />
              <b>1. CONTENIDO DE LA LICENCIA</b> El usuario obtendrá la aplicación y la documentación con el derecho intransferible y no exclusivo de uso. El alcance de este derecho queda exclusivamente determinado por los términos y condiciones establecidos en este acuerdo. Cualquier otro uso o explotación quedan excluidos. Si alguna de las cláusulas de esta licencia no se ajusta a la legalidad quedará anulada pero el resto de la licencia mantendrá su efecto.<br />
              <b>2. MODIFICACIÓN DE LA LICENCIA</b> Axioma Studios puede modificar esta licencia en cualquier momento. El usuario deberá revisarla frecuentemente. La versión más reciente de esta licencia estará disponible en <a href="/#privacy">agromanager.axiomastudios.com/#privacy</a>. La licencia modificada entrará en vigor tan pronto como se realice la modificación. Si el usuario no está de acuerdo con cualquier modificación realizada en la licencia, deberá dejar de usar el Servicio. Si el usuario no deja de utilizarlo, dicha utilización proseguirá con sometimiento a las condiciones de la licencia modificada.<br />
              <b>3. CONCESIÓN DE LICENCIA</b> Según los términos de este acuerdo, se le otorga a Usted una licencia no exclusiva y no transferible que le permitirá usar el programa para uso personal o interno, siempre y cuando todas y cada una de las copias realizadas contengan todos los avisos de propiedad sin modificaciones, incluyendo este acuerdo de licencia.<br />
              <b>4. PROPIEDAD INTELECTUAL</b> Tanto la App (aplicación movil), como los correspondientes derechos de propiedad industrial o intelectual son propiedad de Axioma Studios y están protegidos por las leyes Argentinas de Propiedad Intelectual y por las disposiciones de los tratados internacionales que sean de aplicación.<br />
              <b>5. RESTRICCIONES</b> Usted reconoce y acuerda que no podrá: (a) modificar o crear ningún producto derivado del programa o sus documentos; (b) intentar descompilar, desensamblar, realizar ingeniería inversa o intentar derivar el código fuente del programa; (c) redistribuir, gravar, vender, arrendar o alquilar ninguna parte del programa a un tercero; o (d) retirar o alterar cualquier marca registrada, logotipo o cualquier otro aviso, leyenda, símbolo o etiqueta de propiedad contenida en el programa o producto.<br />
              <b>6. GARANTÍA LIMITADA</b> Este programa y la documentación relacionada se proporcionan "en el estado en que se encuentran" sin garantía ni condición de ningún tipo, expresa o implícita, incluyendo, pero sin limitarse a, las garantías y condiciones de comerciabilidad, idoneidad para un determinado fin y ausencia de infracción. Usted asume todo riesgo que surja de la utilización o del rendimiento de este programa.<br />
              <b>7. LIMITACIÓN DE RESPONSABILIDAD</b> El Usuario será responsable de cualquier daño o pérdida de utilidad de la App y asume totalmente el riesgo de instalar y probar el software.<br /><br />
              El propietario de la App, no ofrece ninguna otra garantía, ni explícita ni implícita, ni estatutaria ni ninguna otra, respecto al manual y al software del programa y en ningún caso será responsable de pérdidas directas o indirectas, incidentales o consecuenciales, que resulten de cualquier defecto de la App, incluso cuando se haya avisado de la posibilidad de dichas pérdidas. El propietario de la App no es responsable bajo ninguna circunstancia de: a.) Reclamaciones de Terceros en contra del cliente por pérdidas, daños o perjuicios. b.) Pérdida de los registros, bases o información del cliente o el daño de cualquiera de ellos o pérdida de confidenciabilidad de los datos. c.) Daños o perjuicios económicos indirectos, lucro cesante o daños incidentales o potenciales, aun cuando se haya informado de esa posibilidad.<br /><br />
              El propietario de la App no garantiza la operación ininterrumpida o libre de todo error del programa. Tampoco será responsable del inadecuado funcionamiento del sitio web si ello obedece a labores de mantenimiento, a incidencias, a un defectuoso funcionamiento del terminal o su insuficiente capacidad para soportar los sistemas indispensables para hacer uso del servicio. Asimismo, no puede responsabilizarse por los retrasos que sean debidos a los servicios de telecomunicaciones.<br /><br />
              El propietario de la App no se hará responsable de los costos de los servicios de reparación que deban ser efectuados por el uso de la App.<br /><br />
            </span>
          </div>
          <div id="account-removal" style={styles.privacy}>
            <span style={styles.privacyTitle}>
              Remover cuenta
            </span>
            <span style={styles.privacyDescription}>
              Para remover su cuenta y todos sus datos, por favor enviar un email a la dirección info@axiomastudios.com, por medio de la cuál comenzaremos los trámites para eliminación de datos y posterior eliminación de la cuenta.<br /><br />
              Al eliminar la cuenta se borrará la siguiente información: <b>Usuario y Contraseña, como así también la información relativa a los campos</b>. Retendremos la información relativa a los Lotes y sus labores, pues no contienen ninguna información que pueda vincularse a la cuenta del usuario.
            </span>
          </div>
        </div>
        <div style={styles.footer}>
          <a href="http://www.axiomastudios.com" rel="noopener noreferrer" target="_blank" style={styles.axiomaLink}><img src={logoAxioma} style={styles.logoAxioma} alt="logo Axioma" /></a>
        </div>
      </div>
    );
  }
}

export default App;
